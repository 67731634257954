




















































import Vue from 'vue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-tw';
const Ripple = require('vue-ripple-directive');
import moment from 'moment';
import axios from 'axios';

export default Vue.extend({
  components: {
    DatePicker,
  },
  directives: {
    Ripple,
  },

  data() {
    const range = [moment().subtract(1, 'days').valueOf(), moment().valueOf()];
    return {
      display: false,
      columns: [
        {
          key: 'monitorType',
          label: '測項',
          sortable: true,
        },
        {
          key: 'startTime',
          label: '開始時間',
          sortable: true,
        },
        {
          key: 'endTime',
          label: '結束時間',
          sortable: true,
        },
        {
          key: 'zero_val',
          label: '零點讀值',
          sortable: true,
        },
        {
          key: 'span_val',
          label: '全幅讀值',
          sortable: true,
        },
        {
          key: 'span_std',
          label: '全幅標準值',
          sortable: true,
        },
      ],
      rows: [],
      form: {
        range,
      },
    };
  },
  methods: {
    async query() {
      this.display = true;
      const url = `/CalibrationReport/${this.form.range[0]}/${this.form.range[1]}`;
      const res = await axios.get(url);
      const ret = res.data;
      this.rows = ret;
    },
  },
});
